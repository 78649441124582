
::v-deep {
    .sub-visual {
        background-color: rgba(0, 0, 0, 0.25);
    }
    .v-tabs {
        background-color: transparent !important;
        &:before {
            background-color: rgba(0, 0, 0, 0.25) !important;
        }
    }
    .v-tab {
        color: #fff !important;
        background-color: transparent !important;
        &.v-tab {
            &--active {
                font-weight: 400;
                color: #fff !important;
                background-color: transparent !important;
                pointer-events: auto;
            }
            &--selected {
                font-weight: 700;
                color: var(--v-primary-base) !important;
                background-color: #fff !important;
                pointer-events: none;
            }
        }
    }
}
